import Cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

/**
 * Read cookie
 * @param {string} name
 */
export const readCookie = (name) => Cookie.get(name);

/**
 * Remove a cookie
 * @param {string} name
 * @returns
 */
export const removeCookie = (name) => Cookie.remove(name);

/**
 * Write cookie
 * @param {string} name
 * @param {any} value
 * @param {any} options
 */
export const writeCookie = (name, value, options) => {
  try {
    const val = Cookie.set(name, value, options);
    return !!val;
  } catch (e) {
    if (process.env.NODE_ENV === 'development') {
      console.log(`Unable to set ${name} cookie with value ${value}`, e);
    }
    return false;
  }
};

export const testCookieAvailability = (domain) => {
  try {
    const currentUserId = readCookie('_bamls_usid');
    const randomValue = uuidv4();
    const testValue = `${currentUserId || randomValue}_test`;
    const canWriteCookies = writeCookie('_bamls_usid', testValue, { expires: 1, ...(domain ? { domain } : {} ) });
    const sessionOnlyCookie = !document.cookie.includes(`_bamls_usid=${testValue}`);
    const cookiesAvailable = canWriteCookies && !sessionOnlyCookie;
    if (currentUserId) {
      // Write back same userId value
      writeCookie('_bamls_usid', currentUserId, { expires: 365, ...(domain ? { domain } : {} ) });
    } else {
      // Remove the test value
      removeCookie('_bamls_usid');
    }
    return cookiesAvailable;
  } catch (err) {
    if (process.env.NODE_ENV === 'development') {
      console.log('Cookies are not available', err);
    }
    return false;
  }
};
